.switLado{

    background-color: #924ccb;
    position: fixed;
    width: 40px;
    height: 40px;
    z-index: 9;
    left: calc(100% - 40px);
    top: 25%;
    border-radius: 10px 0px 0px 10px;
    box-shadow: -4px 0px 3px -3px #000;
    cursor: pointer;
    border:none;

}
 
 
.switLado i{

    font-size: 19px;
    margin-left: 7px;
    margin-top: 2px;
    color: #fff;

}


.switLadoOpen{

    background-color: #924ccb;
    position: fixed;
    width: 160px;
    height: 60px;
    z-index: 80;
    left: calc(100% - 193px);
    top: 25%;
    border-radius: 10px 0px 10px 10px;
    box-shadow: -1px 0px 2px -3px #000;
    cursor: pointer;
    color: #fff;

}

.font_center_switdash{

    margin-top: 10px;
    
}


.selectDashBoard{

    border:none;
    border-radius: 5px;
    margin-top: 5px;
    box-shadow: 0px 1px 4px -2px #000;

}