#tempo_investido .apexcharts-legend.center.position-bottom{

    left: none !important;
    display: none;

}


#chart_desempenho .apexcharts-legend.center.position-right{

    right: -50px !important;

}

.course_cima_student img{max-height: 85px; cursor:pointer;}

.course_cima_student{ 
    
    text-align: center; 
    margin-right:15px; 
    margin-left:15px; 
    margin-top:5px;

}

.course_cima_student h4{ margin-top:5px;}

.title_student_course{ margin-top:15px; }

.course_title_top_student{color:#fff;}

.box_cim_course_student{

    background-color: #0000000d;
    border-radius: 20px;
    box-shadow: 0px 6px 8px -10px #000;

}

#chart_student_nota .apexcharts-legend.left.position-bottom{
 
    justify-content: center !important;
    
}

 