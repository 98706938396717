.erroInputBase{

	color:red;
	margin-top: 5px;

}

.imgup{

	height:100px !important;
	margin-bottom: 20px;

}

.bg-info{

	background: linear-gradient(-45deg,#7b142e,#d12646) !important;

}

.bg-gradient-info{

	background: linear-gradient(-45deg,#7b142e,#d12646) !important;
	
}

.ishidden_unit_c{ opacity: 0.5; }

.ishidden_unit_c:hover{ opacity: 1;}

.paneUnitMove{

	background-color: #fff;
	border-radius: 10px;
	padding: 0px 15px !important;
}

.paneUnitMove .list-group-item{

    background-color: #fff0 !important;
    border: 1px solid #e9ecef00 !important;

}

.paneUnit2Move{

	background-color: #fff;
	border-radius: 0px;
	padding: 0px 15px !important;

}

.iconUnitDialog{

	max-height: 150px;
	
}

.paneLessonMove{ 

	border-radius: 15px;

}

.timeline-one-side:before{

    height: -webkit-fill-available;

}

.avatarIconCourse{

    background-color: #adb5bd00 !important;
    border-radius: 0px 0px 0px 0px !important;

}

.avatarIconCourse img{ border-radius: 0px 0px 0px 0px !important; }

.lessonInputTagSomentTag{ background-color: #fff0; }
.lessonInputTagSomentTag .tagInputClass{ display: none; }
.lessonInputTagSomentTag .removeClass::after{ display: none; }
.lessonInputTagSomentTag .removeClass{ font-size: 18px; top: 49%; }


.lessonInputTagSomentInput .tag-wrapper{ display: none; }


.rc-time-picker-input{    padding: 0 !important; border: 1px solid #f8f9fe00 !important;     color: #8898aa !important; font-size: 0.875rem !important;}

.rc-time-picker-panel-inner{     

    border: 1px solid #cccccc00 !important;
    box-shadow: 0 1px 5px #fdfdfd !important;
    color: #8898aa !important;
	padding: 0px;
    margin-top: 13px;

}

.rc-time-picker-panel-input{ color: #8898aa !important; font-size: 0.875rem !important;}

.rc-time-picker-panel-input-wrap{ border-bottom: 1px solid #e9e9e9;  padding: 2px 0px 10px 11px;}

.navbar-brand{

    padding: 0px;

}

.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand .logo_principal{

	max-width: 100% !important;
    max-height: 9999px !important;
    height: 88px !important;

}

.navbar-brand{

	padding-left: 2px;

}

.paneUnit3Move .ql-editor{    height: 96px;}
.paneUnit3Move { background-color: #fff; }
.paneUnit3Move svg{pointer-events: none;}

.rna-wrapper  div{ z-index: 99999 !important; }

.header-shadow{

	left: 19px; bottom: -30px; background: url('https://i.imgur.com/fh4ldeD.png') no-repeat top; height: 30px; z-index: 299;

}

.viewLesson{

	background-color: #fff;

}
 
.question-answer > :first-child{ pointer-events: none; }

.listLessonsTop{

    overflow: hidden;
    width: 100%;
    display: -webkit-box;
	box-shadow: 0px 11px 8px -11px #000;
    padding-left: 20px;
    border-radius: 0px 0px 15px 15px;
    width: max-content;

}

.dashboard_class_userList_user:first-child{
 
}

.buttonOpenDashBoard{

    position: absolute;
    left: calc(100% - 26px);
    top: 7px;
 
}

.lessonTop{

	width: 300px;
    height: 28px;

}

.inputViwerName_classroom{

    border: none;

}

.dashboard_class_user{
    border-top: 1px solid #ffffff00;
    position: relative;
}

.dashboard_class_user{

	border-top: 1px solid #e9ecef;
	padding-bottom: 3px;
	padding-top: 0px;
	padding-left: 10px;

}

.dashboard_class_user .avatar{

	width: 30px;
	height: 30px;
    margin-right: 3px !important;

}

.dashboard_class_userList_user{

	height: 55px;
	border-top: 1px solid #e9ecef;
	padding-bottom: 10px;
   
    display: -webkit-box;
    overflow:hidden;
    padding-left: 20px;
    position: relative;
    width: max-content;

}

.listTime_dashboard_class_room{

		width: 300px;
		position: relative;
}

.hidden_progress{ display: none; }

.outras_tentativas{

	color: #000;
    box-shadow: 0px 2px 2px -3px #000;
    padding-left: 0px;
    margin-bottom: 0px;
    font-size: 13px;

}

.outras_tentativas .date{


}

.outras_tentativas .timer{
    margin-right: 10px;
    float: right;
    color:#727c97;
}

.tot_register{

    font-size: 28px;
    color: #0000002e;
	float:right;
    margin-right: 10px;

    top: 5px;
    position: absolute;
    left: calc(100% - 55px);
    font-family: monospace;

}

.resto_progresso{ margin-top: 10px; }

.rrasta{ pointer-events: none; }

.p1 {
    position: absolute;
}

#qr {
    position: absolute;
    top: 80px;
    left: 385px;
    border-radius: 10px;
    box-shadow: 0px 2px 7px -1px #000;
    background-color: #fff;
    padding: 8px;
}

.padrao_cartao {
    position: relative;
    width: 600px;  
    height: 340px;
    
}

.p1 i {
    background-image: linear-gradient(#931736, #d12646);
    color: #fff;
    font-size: 18px;
    padding: 9px;
    border-radius: 27px;
    box-shadow: 0px 1px 3px -1px #000;
}

.p1 font {

    margin-left: 16px;
    font-weight: 700;
    position: relative;
    top: -3px;
    color: #000;

}

.row_carduser{ 
 
    z-index: -999;
    opacity: 0;
    margin-top: 10px;
    width: 1260px;
    height: 340px;
    position: fixed;
    z-index: 0;

}

.congratulations{

  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 40%;
  left: 50%;
  margin: -100px 0 0 -150px; 

}

.textViwerHtml{

    margin: 20px 20px 0px 20px;

}

.legenda_minimal_description{

    display: flex;
    margin-bottom: 5px;
    padding-left: 5px;

}

.legendaRelatorioTurma{

    margin-bottom:5px;
    height: 150px;
    overflow-y: auto;

}

.dashboard_class_user_5{ padding-top: 13px; }

.dashboard_class_user_2{

    padding-top: 12px;

}

.legendaRelatorioTurma font{ 

    margin-left:10px; 
    margin-top:1px; 
    font-weight: 600;  

}

.legendaRelatorioTurma .lengd{ margin-right:5px; }

.lengd{ cursor: help; }


.listTime_dashboard_class_room_min{

   
    border-right: 0.6px solid #e6e6e6;
}

.lessonTop_minshadown{

    box-shadow: 0px 3px 0px -2.5px #d9d9d9;
    -webkit-backface-visibility:hidden;

}

.linha_icone{

    height: 0.6px;
    width: 1000px;
    top: 132px;
    position: absolute;
    background-color: #e8e8e8;
    display: none;

}

.nameUnitMinDashBoardClassroom{

    position: absolute;
    top: -4px;
    left: 2px;

}

.nameUnitMinDashBoardClassroom_icon{

    left: -4px;
    position: absolute;
    display: none;

}

.icon_progressunit_1{

    height:24px;

}
.calendarStudend{

    display: flex;
    margin-bottom:10px;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    width: 100%;
    border-radius: 5px;
    overflow:hidden;

}
.calendarStudendLesson{
 
    width: 200px;

}

.divCenterCalendarStudent{

    width:200px;

}

.imgDivCenterCalendar{

    width: 25px;

}

.divCenterCalendarStudent.lesson{

    font-weight: bolder;
    font-size: 14px;

}

.divCenterCalendarStudent.lock_desc_lesson{

    margin-top:10px;

}

.calendarStudendLesson{
    margin-top:10px;
    margin-bottom:5px;
}

.imgCenterCalendarA1{
    max-height: 135px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.divIconLeftCalendarDash{
    border-radius: 5px 0px 0px 5px;
     padding-top: 38px;
    padding-left: 7px;
    background-color: #eff1fc;
    padding-right: 7px;
    box-shadow: 2px 0px 9px -7px #000;
    cursor: pointer;
    position: absolute;
    height: calc(100% - 10px);
    z-index: 1;
}

.divIconRightCalendarDash{
    border-radius: 0px 5px 5px 0px;
    padding-top: 38px;
    padding-left: 7px;
    background-color: #eff1fc;
    padding-right: 7px;
    box-shadow: -2px 0px 9px -7px #000;
    cursor: pointer;   
    position: absolute;
    height: calc(100% - 10px);
    left: calc(100% - 20px);
    z-index: 1;
}


.tabCimaImportXLSX div{

    font-size: 16px;
    width: 120px;
    margin-right:20px;

}

.tabCimaImportXLSX input{

    width: 110px;

}

.div_tab_importxlsx td{
    
    background-color: #fff;

}

.div_tab_importxlsx input{ border:none; }
.div_tab_importxlsx select{ border:none; }

.table_importXLXS td{ padding: 4px; }

.table_importXLXS td{

    padding-left: 5px !important;
    padding-right: 0px !important;

 }

.table_importXLXS tr:hover td{ background-color: #f7f7f7; }

.div_tab_importxlsx th{

    padding-left: 5px !important;
    padding-right: 0px !important;

}
 

.navbar-vertical.navbar-expand-xs{

    max-width: 55px;

}

@media (min-width: 1200px){

    .sidenav:hover {
        max-width: 250px !important;
    }

}

.g-sidenav-hidden:not(.g-sidenav-show) .sidenav .nav-link-text{

    display: block !important;

}

.sidenav:hover .navbar-brand{

    display: block;

}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link{

    padding: 0.675rem 1.35rem;
    
}

.navbar-vertical.navbar-expand-xs.fixed-left + .main-content{

   

}


.fastShow{

    animation-duration: 0s !important;

}

.dashboard_class_user:hover{ background-color: #f1f1f1; }

.dashboard_class_user:hover .inputViwerName_classroom{ background-color: #f1f1f1; }
.inputViwerName_classroom{ background-color: #f7fafc; }
.box_left_detailed{ padding-right: 0px; }


.dialogfastuserchart .apexcharts-legend{ top: 37px !important; }


.select_charts_fast .dropdown{

    width: 230px;
    text-align: justify;
    z-index:999;
    
}

.select_charts_fast{ 
    
    text-align: center;  

}

.select_charts_fast .dropdown-heading{

    border: 1px solid rgb(255, 255, 255) !important;
    box-shadow: 0px 1px 4px -4px #000 !important;
}

.select_charts_fast .dropdown-content{

    border-color: #fff !important;
    box-shadow: 0px 1px 4px -4px #000 !important;
}


.chartCol12{ margin-bottom: 30px; }





.iframeEngine{

    position: absolute;
    top:0px;
    z-index: 999;
    
}


.txtLessonTop{

    white-space: nowrap;
    position: relative;
    width: 50px;
    transform: rotate(-52deg);
    height: 28px;
    top: 20px;

}

.txtLessonTop:last-child{ margin-right: 90px;}


.login_mais{ 
    
    height: 14px !important;
    margin-left: 5px; margin-right: 5px; 

}

.logologin_super,.logologin_cs { height:65px; }

.sidenav-header .login_mais{ 
    
    height: 12px !important; 

}

.sidenav-header .logologin_super{

    height: 30px !important;

} 

.sidenav-header .logologin_cs{

    height: 30px !important;

}

.block_unit{ 

    position: absolute;
    
    width: 100%;
    height: 100%;
    z-index: 9;
    padding-bottom: 15px;

}

.block_unit_aa{

    background-color: #142b3a;

    box-shadow: 0px 1px 5px -3px #000;
    border-radius: 5px;

    position: relative;
    
    width: 100%;
    height: 100%;
}

.block_unit_aa font{

    color:#fff;
    font-weight: 600;
    font-size: 15px;
    position: relative;
    top: 6px;

}



.input_select_custom_importa_usuario > div > div{ position: inherit !important; }

.input_select_custom_importa_usuario .react-dropdown-select-dropdown{

    left: 37%;
    width: 300px;
    overflow-x: hidden;
    border-radius: 10px;
    border: 0px solid #ccc;
    padding-top: 10px;
    padding-bottom: 10px;
     
}


.input_select_custom_importa_usuario .react-dropdown-select{  background-color: #fff;  border-color: #0074d900; box-shadow: 0 0 0 3px rgba(0, 116, 217, 0); }
.input_select_custom_importa_usuario .react-dropdown-select:hover{    border-color: #0074d900; box-shadow: 0 0 0 3px rgba(0, 116, 217, 0); }
.input_select_custom_importa_usuario .react-dropdown-select:focus{    border-color: #0074d900; box-shadow: 0 0 0 3px rgba(0, 116, 217, 0); }

.input_select_custom_importa_usuario .custom-checkbox{

    margin:1px 10px;

}

.input_select_custom_importa_usuario .custom-checkbox:hover{

    background-color: #f3f3f3;
    border-radius: 5px;
    
}

.input_select_custom_importa_usuario .react-dropdown-select-dropdown{ width: auto !important; overflow-x: auto !important; }
 

.vertical-timeline:before {
    background-color: #ddd;
}

.select_professor_bb .select-search-box__search{

    box-shadow: 0px 0px;
    margin-top: 3px;
    width: 100%;
    padding: 0 9px;

    color: #8898aa;
}

.select_professor_bb .select-search-box{ width: 100% !important; }


.div_custom_classroom{

    display: flex;
    justify-content: center;

}

.div_custom_classroom .frsc{

    font-size: 15px;
    margin-left: 5px;

}