.buttonOpenChat {
    position: fixed;
    right: 30px;
    bottom: 20px;
    width: 60px;
    height: 60px;
    background: linear-gradient(87deg, #924ccb 0, #c783ff 100%);
    border-radius: 30px;
    z-index: 9;
    cursor: pointer;
}    
 
.buttonOpenChat{
    color:#fff;
    text-align: center;
    font-size: 30px;
}

.buttonOpenChat .iconOpenChatMarlon{
    margin-top:14px;
 }

 .buttonOpenChat .iconCloseChatMarlon{
    margin-top:14px;
 }

 .chatMarlonWindow {
    width: 370px;
    height: calc(100% - 120px);
    max-height: 590px;
    position: fixed;
    right: 25px;
    bottom: 100px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 7px 40px 2px hsla(210,1%,58%,.3);
    box-shadow: 0 7px 40px 2px hsla(210,1%,58%,.3);
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify; 
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    border-radius: 10px;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    z-index: 9999;
}

.chatMarlonGroupUser font{
    font-size:15px;
}

#chatMarlonContentMenssage{
    height: 56px;
    margin-top: 0px;
    background-color: #f4f7f9;
    border: none;
    padding-left: 10px;
    border-radius: 0px 0px 10px 10px;
    width: 370px;
}

#chatMarlonContentMenssage:focus{
    box-shadow: 2px -1px 18px -14px #000;
    background-color:#fff;
    color:#565867;
}

.chatMarlonUsers{
    overflow-y: auto;
    overflow-x: hidden;
}

.chatMarlonUser{ cursor:pointer; }

.inputMarlonChatSearch{
    width: 100%;
    border: none;
    padding:10px;
    background-color: #f6f9fc;
}

.iconMarlonChatSearch{
    padding-top: 13px;
    padding-left: 10px;
    background-color: #f6f9fc;
}

.chatMarlonGroupUser .list-group-item:hover{
    cursor:pointer;
    box-shadow: 0px 1px 7px -6px #000; 
}

.semMsgMarlon{
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;    
}

.px-0.list-group-item.chatMarlonUser:hover{
    cursor:pointer;
    box-shadow: 0px 1px 7px -6px #000; 
}

.chatMarlonGroupUser{
    margin-left:10px;
    margin-top:10px;
    font-weight:600;
}

.chatMarlonHeader {
    background: #4e8cff;
    min-height: 75px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    color: #fff;
    padding: 10px;
    -webkit-box-shadow: 0 1px 4px rgba(0,0,0,.2);
    box-shadow: 0 1px 4px rgba(0,0,0,.2);
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.chatMarlonListMensagems {
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    background-size: 100%;
    padding-bottom: 20px;
}

.chatMarlonForm {
    min-height: 55px;
    margin: 0;
    position: relative;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    -webkit-transition: background-color .2s ease,-webkit-box-shadow .2s ease;
    transition: background-color .2s ease,-webkit-box-shadow .2s ease;
    -o-transition: background-color .2s ease,box-shadow .2s ease;
    transition: background-color .2s ease,box-shadow .2s ease;
    transition: background-color .2s ease,box-shadow .2s ease,-webkit-box-shadow .2s ease;
    position: fixed;
    top: calc(100% - 56px);
}

.chatMarlonContats{
    font-size: 25px;
    margin-top: 13px;
    margin-right: 5px;
    margin-left: 5px;
}

.sc-user-input--text {
    width: 300px;
    resize: none;
    border: none;
    outline: none;
    border-bottom-left-radius: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 18px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.33;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #565867;
    -webkit-font-smoothing: antialiased;
    max-height: 200px;
    overflow: scroll;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.chatMarlonHeader--img, .chatMarlonHeader--team-name {
    -ms-flex-item-align: center;
    align-self: center;
    padding: 10px;
    font-size: 16px;
    font-family: sans-serif;
}

.chatMarlonHeader--team-name{
    padding-left: 0px;
}

.chatMarlonMensagems{
    height: calc(100% - 136px);   
}


.chatMarlon.returnUsers{
    font-size: 20px;
    height: 30px;
    width: 30px;
    text-align: center;
    border-radius: 5px;
    padding-top: 5px;
    margin-top:15px;
    cursor: pointer;
}

.chatMarlon.returnUsers:hover{
    background: #4882ed;
}

.chatMarlonHeader--img {
    border-radius: 50%;
}

.chatMarlonHeader--close-button img {
    width: 100%;
    height: 100%;
    padding: 13px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.chatMarlonHeader--close-button {
    width: 40px;
    -ms-flex-item-align: center;
    align-self: center;
    height: 40px;
    margin-right: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 5px;
    position: absolute;
    left: calc(100% - 55px);
}

.chatMarlonHeader--close-button:hover {
    background: #4882ed;
}

.chatMarlonMensagem--content.sent{
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    float: right;
}

.chatMarlonMensagem--content{
    width: 100%;
}

.chatMarlonMensagem--content.sent .sc-message--avatar{
    display: none;
}

.chatMarlonMensagem{
    display: flex;
    margin-bottom:10px;
    margin-top:10px;
}

.chatMarlonMensagem--avatar {
    background-image: url(https://d13yacurqjgara.cloudfront.net/assets/avatar-default-aa2eab7….gif);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50%;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    -ms-flex-item-align: center;
    align-self: center;
    margin-right: 15px;
}

.chatMarlonMensagem--content.received .chatMarlonMensagem--text {
    color: #263238;
    background-color: #f4f7f9;
    margin-left: 10px;
}

.chatMarlonMensagem--content.sent .chatMarlonMensagem--text {
    color: #fff;
    background-color: #4e8cff;
    max-width: calc(100% - 120px);
    word-wrap: break-word;
    float: right;
    margin-right: 10px;
}

.chatMarlonMensagem--text {
    padding: 17px 20px;
    border-radius: 6px;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;
    white-space: pre-wrap;
    -webkit-font-smoothing: subpixel-antialiased;
    word-wrap: break-word;
    width: calc(100% - 90px);
}

.chatMarlonMensagem--text {
    padding: 17px 20px;
    border-radius: 6px;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;
    white-space: pre-wrap;
    -webkit-font-smoothing: subpixel-antialiased;
    word-wrap: break-word;
    width: calc(100% - 90px);
}
 
.bottomMyContatsMarlon{
    background-color: #4e8cff;
    color:#fff;
    position: fixed;
    width: 100%;
    height: 40px;
    padding-top: 10px;
    top: calc(100% - 40px);
    border-radius: 0px 0px 5px 5px;
    z-index: 9;
    cursor: pointer;
}

.inputViewLastMessageMarlon{
    border: none;
    cursor: pointer;
}

.chatViewUserOnlineMarlon{ 
    position: absolute;
    top: 65px;
    left: 20px;
}

.chatMarlonNotifi{
    top: 20px;
    background-color:#fff;
    box-shadow: 0px 1px 9px -4px #000;
    margin:0 auto;
    width: 300px;
    position: fixed;
    left: 40%;
    border-radius: 30px 10px 10px 30px;
    z-index: 9999;
    display:flex;
    cursor:pointer;

}

.chatMarlonNotifi img{
    width: 50px;
}

.marlonNname{
    margin-top:6px;
    font-weight: 700;
}

.chatMarlonNnN{
    margin-left: 10px;
}
.chatMarlonNnN input{
    border:none;
    width: 100%;
    padding-right: 20px;
    cursor:pointer;
}