.wrapperQuiz h2 {
  font-size: 1.25em;
  margin: 0 0 15px;
}

.wrapperQuiz header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.wrapperQuiz ul {
  padding: 0;  
}

.wrapperQuiz p {
  margin: 0;
}

.wrapperQuiz span {
  pointer-events: none;
}

code {
  font-family: monospace;
  font-size: .8em;
  font-weight: 100;
  pointer-events: none;
  background: rgba(0,0,0,.09);
  padding: 3px 4px;
  margin: 0 2px;
}

.score-container, .question-count {
  width: 250px;
  text-align: center;
}

.score-container h2, .question-count h2 {
  margin-bottom: 0;
  font-size: 2em;
  font-weight: 400;
}

.score, .question-number {
  font-size: 4em;
  font-weight: 100;
}

.description {
  font-size: 1.5em;
}

.questions {
  width: 85%;
  margin: 35px auto 0;
}

.question {
  font-size: 2em;
}

.question-answers {
  margin-top: .75em;
  padding-left: 1.2em;
  border-radius: 20px;
}

.question-answer {
  list-style-type: none;
  cursor: pointer;
  padding: .3em;
  margin-bottom: .3em;
  border: 5px solid transparent;
}

.question-answer span {
  line-height: 1.3;
}

.answer {
  font-size: 1em;
}

@keyframes slide-in {
  0%   { opacity: 0; transform: translate3d(40%, 0, 0); }
  100% { opacity: 1; transform: none; }
}

.question {
 
  animation: slide-in .4s ease;
  list-style-type: none; 
  
}

.question:first-child {
 
}

.results-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-size: 1.75em;
  line-height: 1.75em;
  animation: slide-in .4s ease;
}

.results-total {
  margin-top: 15px;
  font-size: 1.1em;
}

.results-container a {
  position: relative;
  padding: 15px 30px;
  margin-top: 30px;
  border: 3px solid #111;
  background: none;
  cursor: pointer;
  font-size: .75em;
  transition: background .2s;
}

.question p{
    font-family: inherit;
    font-weight: 600;
    line-height: 1.5;
    color: #32325d;
    font-size:22px;
}

.results-container a:hover {
  background: rgba(255,255,255,.1);
}

.results-container a:active, {
  background: rgba(255,255,255,.5);
  top: -2px;
}

.wrong {
  background: rgb(255, 0, 0);
  animation: shake 0.7s cubic-bezier(.35,.05,.20,.99) both;
  border-radius: 20px;
  box-shadow: 0px 2px 1px -1px #000;
  color: #fff;
  opacity: 0.4;
}

.wrong span{ color:#fff!important; }
.wrong p{ color:#fff !important; }

.right_quiz {

  background: rgb(14, 175, 78);
  color:#fff;
  border-radius: 20px;
  box-shadow: 0px 2px 1px -1px #000;

}
.right_quiz span{ color:#fff !important; }
.right_quiz p{ color:#fff !important; }

.question-answer.right_quiz:hover { border-color: rgba(50, 50, 93, 0) !important;  }
.question-answer.wrong:hover { border-color: rgba(50, 50, 93, 0) !important;  }

.praise{ color:#fff; }
.points{ color:#fff; }

@keyframes shake {
  10%, 90% {
    transform: translateX(-1px);
  }
  20%, 80% {
    transform: translateX(1px);
  }
  30%, 50%, 70% {
    transform: translateX(-2px);
  }
  45%, 55% {
    transform: translateX(2px);
  }
}

.correct-modal {
  font-size: 5em;
  text-align: center;
  width: 100%;
  background: #53c4d8;
  padding: 5%;
  will-change: transform;
  z-index: 2;
  opacity: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  display: none;
  box-shadow: 0px 0px 3px 0px #000;
}

.correct-modal > *{

  text-shadow: 0px 1px #000; 
  
}

.correct-modal.modal-enter {
  display: flex;
  #animation: modal-enter 6.0s ease-in both;
}

.praise, .points {
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}
/*
@keyframes modal-enter {
  0 {
    visibility: visible;
    opacity: 1;
  }
  20% {
    transform: scale(1);
    opacity: 1;
  }
  30% {
    transform: scale(1);
    opacity: 1;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  85% {
    opacity: 0.7;
    transform: scale(1);
  }
  99% {
    opacity: .5;
    transform: scale(1);
  }
  100% {
    display: none;
    transform: scale(1);
  }
}
*/

@media (min-width: 600px) {
  body {
    font-size: 12px;
  }
}

.question-answer{

  border-radius: 20px;

  transition-duration: 0.3s;
  -webkit-transition-property: all;
  transition-property: all;

}

.question-answer:hover {
   
  border-radius: 20px;
  background-color: #609edb;
  color: #fff;
  box-shadow: 0px 3px 7px -4px #000;

}

 
@media (min-width: 900px) {
 

  .questions {
    width: 75%;
  }


 
  .correct-modal {
    height: 300px;
  }

}

@media (min-width: 1400px){
 
  .correct-modal {
    height: 400px;
  }

}
 

 
/*
.question-answer *{
  font-family: inherit !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  font-size: 16px !important;
  color: #32325d;
}
 

.question-title *{

  font-size: 17px !important;
  font-weight: 600 !important;

}*/