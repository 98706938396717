.tab_select_classroom{

    z-index: 999;
    color: #32325d;
    left: calc(0% + 300px);
    position: fixed;
    

}

.tab_select_classroom .pp_1{}
.tab_select_classroom .pp_1{

    background-color: #fff;
    border-radius: 5px;

    padding-left: 8px;
    padding-right: 8px;
    padding-top: 10px;
    padding-bottom: 10px;

    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
    box-shadow: 0px 2px 5px -2px #000;

    width: 100%;
    border: none;

}

.tab_select_classroom .pp_2{ 
    
    color: #5f5f5f; 
    font-size: 25px;

}

.tab_select_classroom .pp_3{ 

    color: #000000;
    font-size: 14px;
    font-weight: initial;

}

.tab_select_classroom .ativo{

    background-color: #4c97ff !important;
    
}


.tab_select_classroom .ativo .pp_2{

    color:#fff !important;

}

.tab_select_classroom .ativo .pp_3{

    color:#fff !important;

}
 

.vertical-timeline-element-content .vertical-timeline-element-date{

    opacity: 1 !important;

}

.react-rater-star.is-active{

    color: #9aa200 !important;

}

.react-rater-star{

    cursor: pointer;
    color: #000;
    position: relative;
    
}